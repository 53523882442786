<template>
    <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>
  
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-4 mr-2"
            v-bind="attrs"
            v-on="on"
            text
            icon
            color="blue lighten-2 ml-3"
            @click="backToInbox"
          >
            <v-icon>{{ iconBack }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.back-to-inbox") }}</span>
      </v-tooltip>
  
      <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            icon
            color="blue lighten-2 ml-3"
            @click="menuAction('reload')"
          >
            <v-icon>{{ iconReload }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.reload") }}</span>
      </v-tooltip>
  
      <v-tooltip bottom v-if="hasDelegated && !userIsDirPjp && !userIsSekretaris">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            text
            icon
            color="blue lighten-2 ml-3"
            @click="replyMemo(singleMemo.memo.id_hash)"
          >
            <v-icon>{{ iconReply }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.reply-this-memo") }}</span>
      </v-tooltip>
  
      <v-tooltip bottom v-if="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-inline ml-2"
            dark
            color="blue lighten-1 text-white"
            v-bind="attrs"
            v-on="on"
            small
          >
            {{ $t("button.do-disposition") }}
          </v-btn>
        </template>
        <span>{{ $t("tooltip.disposition-this-memo") }}</span>
      </v-tooltip>
  
      <v-menu bottom left>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="ml-2"
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                color="blue lighten-2 ml-3"
              >
                <v-icon>{{ iconCog }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.other-menu") }}</span>
          </v-tooltip>
        </template>
  
        <v-list>
          <v-list-item
            v-for="(item, i) in menus"
            :key="i"
            class="cursor-pointer memo-header-menu"
            @click="menuAction(item.command)"
          >
            <v-icon class="mr-3">{{ item.icon }}</v-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>
  <script>
  import {
    mdiEmailOutline,
    mdiReply,
    mdiTransfer,
    mdiBackburger,
    mdiReload,
    mdiDatabaseClockOutline,
    mdiCog,
  } from "@mdi/js";
  import toolbarMenu from "@/constants/toolbarmenu";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "header-left",
    components: {},
    props: {
      heading: {
        type: String,
        default: "",
      },
    },
    data: () => ({
      iconMail: mdiEmailOutline,
      iconCog: mdiCog,
      iconBack: mdiBackburger,
      iconReply: mdiReply,
      iconDisposition: mdiTransfer,
      iconHistory: mdiDatabaseClockOutline,
      iconReload: mdiReload,
    }),
    computed: {
      ...mapState("inbox", ["singleMemo"]),
      ...mapState("user", ["currentUser"]),
      hasDelegated() {
        if (!this.singleMemo) return true;
        return parseInt(this.singleMemo.memo.has_delegation) === 1;
      },
      userIsDirPjp() {
        if (!this.currentUser) return false;
        return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
          this.currentUser.usertype_elemen
        );
      },
      userIsSekretaris() {
        if (!this.currentUser) return true;
        return ["SEKRETARIS","SEKRETARIS_DIRSEVP"].includes(this.currentUser.usertype_elemen);
      },
      menus() {
        if (this.userIsDirPjp && this.hasDelegated) return toolbarMenu.inboxDetailToolbarMenuHasDelegated;
        return toolbarMenu.inboxDetailToolbarMenu;
      },
    },
    methods: {
      ...mapActions("inbox", [
        "setDispositionDialog",
        "setDispositionHistoryDialog",
        "setPrintPreviewDialog",
        "setBroadcastDialog",
        "setMemoValidationDialog",
      ]),
      ...mapActions(["setMessageBox"]),
      backToInbox() {
        return this.$router.push({ name: "ccsentx" });
      },
      replyMemo(id) {
        console.info("Reply Memo : " + id);
      },
      menuAction(command) {
        switch (command) {
          case "broadcast":
            if (this.userIsSekretaris) {
              return this.$dialog.notify.error(
                this.$t("error.secretary-cannot-broadcast-a-memo"),
                {
                  position: "top-left",
                  timeout: 3000,
                }
              );
            } else if (!this.hasDelegated) {
              return this.$dialog.notify.error(
                this.$t("error.cannot-broadcast-undelegated-memo"),
                {
                  position: "top-left",
                  timeout: 3000,
                }
              );
            }
            this.setBroadcastDialog(true);
            break;
         case "share_link":
           this.$emit("shareLink");
          break;
          case "validate":
            this.setMemoValidationDialog(true);
            break;
          case "reload":
            this.$emit("reloadMemo");
            break;
          case "print_preview":
            // this.setPrintPreviewDialog(true);
            this.$emit("printPreview");
            break;
          case "print_dispo_form":
            // this.setPrintPreviewDialog(true);
            this.$emit("downloadDispoForm");
            break;
  
          default:
            break;
        }
      },
    },
  };
  </script>